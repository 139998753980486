.rent-car-model{
    position: fixed;
    top: 0;
    right: -100%;
    width: 800px;
    height: 100vh;
    overflow-y: auto;
    transition: right 0.4s ease-in-out;
}

.rent-car-model-active{
    right: 0%;
}

.rent-car-model-unactive{
    right: -100%;
}

.rent-car-model .form-parent{
    border: 1px solid #ddd;
    border-top: none;
    padding: 10px;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.edit-delete-modal{
    transform: translate(-50%, -50%);
}
.edit-modal{
    top: 0%;
    right: 0%;
    width: 1000px;
    height: 100%;
}

.accept-modal{
    max-height: 620px;
    overflow-y: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.extend-modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 950px;
}

.stat-modal{
    max-height: 700px;
    overflow-y: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.edit-user{
    position: absolute !important;
    width: 700px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}