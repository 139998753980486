.container-gallery{
    border: 1px solid #ddd;
    margin: 5px;
    margin-top: 0px;
    border-radius: 20px;
    padding: 10px;
}

.container-gallery-heading{
    margin-left: 5px;
    font-size: 30px;
    text-transform: bold;
}

.car-gallery{
    padding-top: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-bottom: 20px;
}

.car-gallery-btns{
    margin-top: 5px;
    width: 180px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.car-gallery-btns-vertical {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

.car-gallery .img-container{
    width: 180px;
    position: relative;
}

.car-gallery .img-container img{
    width: 180px;
    height: auto;
}

.car-gallery .img-container::after{
    cursor: pointer;
    display: none;
    content: "See image";
    text-align: center;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 0%;
    height: 0%;
    background: #1414146a;
    color: white;
    transition: 0.4s ease-in-out;
}

.car-gallery .img-container:hover::after{
    display: block;
    width: 100%;
    height: 100%;
}

